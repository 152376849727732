import { createApi } from '@reduxjs/toolkit/query/react'
import { dynamicBaseQuery } from './helpers'

export const INVENTORY_TAG = 'Inventory'
export const ITEM_TAG = 'Item'
export const TAG_ID = 'tagId'
export const INSURANCE_POLICY = 'Insurance_policy'
export const INSURANCE_POLICY_PRE_REGISTERED = 'insurance_policy_pre_registered'
export const FLOW_TEMPLATES = 'flow_templates'
export const FLOW_STEP_CONTENT_ATTRIBUTES = 'flow_step_content_attributes'
export const FLOW_STEP_ATTRIBUTES = 'flow_step_attributes'
export const FLOW_STEP_CONTENT_ATTRIBUTE_MAP = 'flow_step_content_attribute_map'
export const FLOW_TEMPLATE_FIRST_STEP = 'flow_template_first_step'
export const FLOW_TEMPLATE_STEPS = 'flow_template_steps'
export const ORGANISATION_BRANDS = 'organisation_brands'
export const MY_BIKES = 'MyBikes'
export const BIKE_TYPES = 'BikeTypes'
export const BIKE_STOLEN_PLACE_TYPES = 'BIKE_STOLEN_PLACE_TYPES'
export const BIKE_COLORS = 'BikeColors'
export const BIKE_BRANDS = 'BikeBrands'
export const BIKE_CATEGORIES = 'BikeCategories'
export const STOLEN_BIKE_REPORT = 'StolenBikeReport'
export const BIKE_LOCKS = 'bikeLocks'
export const LOCATION = 'Location'
export const ORGANISATIONS = 'ORGANISATIONS'
export const ORGANISATION_SHORT_LIVED_TOKENS = 'ORGANISATION_SHORT_LIVED_TOKENS'
export const LOCATION_PREDICTIONS = 'location_predictions'
export const BACKOFFICE_SHIPMENTS = 'BackofficeShipments'
export const BACKOFFICE_STOLEN_BIKE_TICKETS = 'backoffice_stolen_bike_tickets'
export const BACKOFFICE_PARTY_TAGS = 'backoffice_party_tags'
export const INSURANCE_COMPANIES = 'INSURANCE_COMPANIES'
export const INSURANCE_ADMIN_POLICIES = 'insurance_admin_policies'
export const INSURANCE_LOCK_TEST_RESPONSES = 'insurance_lock_test_responses'
export const INVENTORY_LOCKS = 'inventory_locks'
export const IDENTITY_CONSENTS = 'identity_consents'
export const BACKEND_CONFIG = 'backend_config'
export const CHAT_UPDATES = 'ChatUpdates'
export const CHATS = 'Chats'
export const TagAbstractIds = {
    LIST: 'LIST',
}

const bikekeyApi = createApi({
    reducerPath: 'bikekeyApi',
    baseQuery: dynamicBaseQuery,
    tagTypes: [
        INVENTORY_TAG,
        ITEM_TAG,
        TAG_ID,
        INSURANCE_POLICY,
        INSURANCE_POLICY_PRE_REGISTERED,
        FLOW_TEMPLATES,
        FLOW_TEMPLATE_FIRST_STEP,
        FLOW_TEMPLATE_STEPS,
        FLOW_STEP_ATTRIBUTES,
        FLOW_STEP_CONTENT_ATTRIBUTES,
        FLOW_STEP_CONTENT_ATTRIBUTE_MAP,
        ORGANISATION_BRANDS,
        MY_BIKES,
        BIKE_STOLEN_PLACE_TYPES,
        BIKE_TYPES,
        BIKE_COLORS,
        BIKE_BRANDS,
        BACKEND_CONFIG,
        BIKE_CATEGORIES,
        STOLEN_BIKE_REPORT,
        BIKE_LOCKS,
        LOCATION,
        ORGANISATIONS,
        ORGANISATION_SHORT_LIVED_TOKENS,
        LOCATION_PREDICTIONS,
        BACKOFFICE_SHIPMENTS,
        BACKOFFICE_STOLEN_BIKE_TICKETS,
        BACKOFFICE_PARTY_TAGS,
        INSURANCE_COMPANIES,
        INSURANCE_ADMIN_POLICIES,
        INSURANCE_LOCK_TEST_RESPONSES,
        INVENTORY_LOCKS,
        IDENTITY_CONSENTS,
        CHAT_UPDATES,
        CHATS,
    ],
    endpoints: () => ({}),
    keepUnusedDataFor: 86400 * 30, // 1 month
})

export default bikekeyApi
