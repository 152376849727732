import * as React from 'react'
import { User } from '../../types/session'
import { navigate } from 'gatsby'
import jwt_decode from 'jwt-decode'
import {
    getStoredAuthToken,
    nullify,
    removeStoredAuthToken,
    storeAuthToken,
} from '../../utils/auth-token'
import * as dayjs from 'dayjs'
import { useLocation } from '@reach/router'
import { goToApp } from '../../actions/auth'
import { refreshAuthToken } from '../identities'
import { redirectWrapper } from '../../utils/redirect'

export type Session = User & { exp: number }

export const useSessionIfExists = (): {
    session: Session
    token: string
    reloadToken: () => void
    fullRefreshToken: () => Promise<void>
    hasLoaded: boolean
} => {
    const [hasLoaded, setHasLoaded] = React.useState(false)
    const [token, setToken] = React.useState<string>(getStoredAuthToken())
    const [sessionData, setSessionData] = React.useState<Session | {}>({})
    const { pathname } = useLocation()

    function reloadToken() {
        setToken(getStoredAuthToken())
    }

    async function fullRefreshToken() {
        try {
            const newToken = await refreshAuthToken()
            if (newToken) {
                storeAuthToken(newToken)
                reloadToken()
            }
        } catch {}
    }

    React.useEffect(() => {
        setSessionData(token ? nullify(jwt_decode(token)) : {})
        setHasLoaded(true)
    }, [token])

    React.useEffect(() => {
        reloadToken()
    }, [pathname])

    return {
        session: sessionData as Session,
        token,
        reloadToken,
        fullRefreshToken,
        hasLoaded,
    }
}

export const useAuth = (
    redirectIfFound: string | undefined = undefined,
    requiredFeatures: string[] = [],
    disableRedirect = false
): { session: Session; reloadToken: () => void; fullRefreshToken: () => Promise<void> } => {
    const { pathname, search } = useLocation()
    const { session, reloadToken, fullRefreshToken } = useSessionIfExists()

    React.useEffect(() => {
        const parameters = new URLSearchParams(search)
        if (disableRedirect) return
        const token = getStoredAuthToken()
        if (!token) {
            if (
                !(
                    pathname.toLowerCase().startsWith('/login') ||
                    (pathname.toLowerCase().startsWith('/signup') &&
                        !pathname
                            .toLowerCase()
                            .startsWith('/signup/from-scan/create-bikes-loop')) ||
                    pathname.toLowerCase().startsWith('/reset-password-succes') ||
                    pathname.toLowerCase().startsWith('/reset-password') ||
                    pathname.toLowerCase().startsWith('/forgot-passw-check-mail') ||
                    pathname.toLowerCase().startsWith('/forgot-password') ||
                    pathname.toLowerCase().startsWith('/flows')
                )
            ) {
                let redirectFunction = redirectWrapper(
                    () => navigate('/login/', { replace: true }),
                    pathname + '?' + parameters,
                    ['loggedIn']
                )
                if (
                    parameters.has('post_login_redirect') &&
                    parameters.get('post_login_redirect')
                ) {
                    redirectFunction = redirectWrapper(
                        () => navigate('/login/', { replace: true }),
                        parameters.get('post_login_redirect') as string,
                        ['loggedIn']
                    )
                }
                redirectFunction()
            }
            return
        }
        try {
            const sessionData = nullify(jwt_decode(token)) as User & { exp: number }
            const expiresAt = dayjs(new Date(sessionData.exp * 1000))
            if (dayjs().isAfter(expiresAt)) {
                removeStoredAuthToken()
                navigate('/login/', { replace: true })
                return
            }
        } catch {
            removeStoredAuthToken()
            navigate('/login/', { replace: true })
        }
        if (redirectIfFound) {
            goToApp(redirectIfFound)
            return
        }
    }, [pathname, session, redirectIfFound, requiredFeatures, search])

    return {
        session,
        reloadToken,
        fullRefreshToken,
    }
}

export function loadSession(token: string | null = null): Session {
    const tokenToUse = token || getStoredAuthToken()
    return tokenToUse ? nullify(jwt_decode(tokenToUse)) : {}
}
