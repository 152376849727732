import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getItem, setItem, removeItem, tokenPaths } from '../../utils/localStorage'

type Language = 'da' | 'en' | 'no'

const languages: Language[] = ['da', 'en', 'no']

export interface LanguageState {
    currentLanguage: Language
}

const initializeState = ({} = {}): LanguageState => {
    let currentLanguage = getItem(tokenPaths.language) || 'da'
    if (!languages.includes(currentLanguage)) {
        currentLanguage = 'da'
        setItem(tokenPaths.language, currentLanguage)
    }
    return {
        currentLanguage,
    }
}

function storeLanguageLocally(language: Language) {
    setItem(tokenPaths.language, language)
}

const languageSlice = createSlice({
    name: 'language',
    initialState: initializeState(),
    reducers: {
        storeLanguage: (state, action: PayloadAction<Language>) => {
            storeLanguageLocally(action.payload)
            state.currentLanguage = action.payload
        },
    },
})

export const { storeLanguage } = languageSlice.actions
export default languageSlice.reducer
