import {
    ChangeInfoParams,
    IdentityConsent,
    LoginParams,
    VerificationSessionTypes,
} from '../types/identity'
import { storeAuthToken } from '../utils/auth-token'
import { httpPost, httpPut, httpGet } from './http'
import { GoogleAuthUserInfo } from '../types/google_auth'
import { getItem, removeItem, setItem, tokenPaths } from '../utils/localStorage'
import * as dayjs from 'dayjs'

export async function loginWithBikekey(values: LoginParams): Promise<string | null> {
    const response = await httpPost<{ authentication_token: string }>(
        `/identities/authentications`,
        values
    )
    return response?.data?.authentication_token || null
}

export async function getUserToken(newUserPartyId: string) {
    const response = await httpPost<{ authentication_token: string }>(
        `/identities/switch-user/${newUserPartyId}`,
        {}
    )
    return response?.data?.authentication_token || null
}

export async function refreshAuthToken(): Promise<string | null> {
    const response = await httpPost<{ authentication_token: string }>(
        `/identities/authentications/refresh`
    )
    return response?.data?.authentication_token || null
}

export async function requestResetPasswordEp(values: { email: string }): Promise<void> {
    await httpPost<{ authentication_token: string }>(
        `/identities/authentications/password-resets`,
        values
    )
}

export async function copmleteResetPassword(
    values: { new_password: string },
    token: string
): Promise<string | null> {
    const response = await httpPost<{ authentication_token: string }>(
        `/identities/authentications/password-resets/${token}/validate`,
        values
    )
    return response?.data?.authentication_token || null
}

export async function resendVerifyEmail(): Promise<void> {
    await httpPost(`/identities/authentications/email-verifications`)
}

export async function completeVerifyEmail(token: string): Promise<string | null> {
    const response = await httpPost<{ authentication_token: string }>(
        `/identities/authentications/email-verifications/${token}/validate`
    )
    return response?.data?.authentication_token || null
}

export async function changeInfo(values: ChangeInfoParams): Promise<string | null> {
    const response = await httpPut<{ authentication_token: string }>(`/identities/me`, values)
    return response?.data?.authentication_token || null
}

export async function getVerificationLink(sessionType: VerificationSessionTypes): Promise<string> {
    const response = await httpPost<{ verification_url: string }>(`/identities/verifications/`, {
        verification_session_type: sessionType,
    })
    if (!response?.data?.verification_url) {
        throw new Error('no authorisation url was returned')
    }
    return response.data.verification_url
}

export async function completeVerification(
    authorisationCode: string,
    sessionId: string | null,
    sessionType: VerificationSessionTypes | null
): Promise<any & { success_url?: string }> {
    const data: any = { code: authorisationCode, session_id: sessionId || undefined }
    if (sessionType) {
        data.verification_session_type = sessionType
        data.verification_type = 'mitid'
    }
    const response = await httpPost<{
        authentication_token: string
        session_id: string
        session_task_result: any
    }>('/identities/verifications/complete/', data)
    const lastUsedSessionType = getItem(tokenPaths.lastInitiatedMitIDSessionType)
    if (sessionType !== 'delete_identity') {
        if (!response?.data?.authentication_token) {
            throw new Error('no authorisation url was returned')
        }
        removeItem(tokenPaths.lastInitiatedMitIDSessionType)
        storeAuthToken(response?.data?.authentication_token)
    }
    setItem(
        tokenPaths.lastCompletedMitIDSession,
        JSON.stringify({
            sessionId: sessionId || response?.data?.session_id,
            timeCompleted: dayjs().toISOString(),
            sessionType: sessionType || lastUsedSessionType,
        })
    )
    return response?.data?.session_task_result
}

export async function addConsent(
    consentType: string,
    body: { partner_party_id?: string; action?: string } = {}
): Promise<void> {
    await httpPost<{ status: string }>(`/identities/consents/${consentType}/`, body)
}

export async function getConsents(): Promise<IdentityConsent[]> {
    const response = await httpGet<{ consents: IdentityConsent[] }>(`/identities/consents/`)
    return response?.data?.consents || []
}

export async function signupWithGoogle(values: GoogleAuthUserInfo): Promise<any> {
    const requestData = {
        name: values.google_name,
        email: values.google_email,
        google_token: values.google_id,
    }
    const response = await httpPost<{ authentication_token: string }>(
        `/identities/google`,
        requestData
    )
    return response?.data?.authentication_token
}

export async function loginWithGoogle(values: { googleUserId: string }): Promise<string | null> {
    const response = await httpPost<{ authentication_token: string }>(
        `/identities/authentications/google`,
        values
    )
    return response?.data?.authentication_token || null
}
