import React from 'react'
import { Provider } from 'react-redux'
import createStore from './src/redux/store'
import { I18nextProvider } from 'react-i18next'
import { useI18Component } from './src/translation/i18n'

const store = createStore()

export default function wrapWithProvider({ element }) {
    const i18nInstance = useI18Component()

    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18nInstance}>{element}</I18nextProvider>
        </Provider>
    )
}
