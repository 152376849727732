import { loadSession } from '../apis/hooks/useAuth'
import { navigateToIndex, navigateToPage } from '../components/blocks/pageWrappers/pageMap'
import { getItem, setItem, tokenPaths, removeItem } from './localStorage'
import { removeStoredAuthToken } from './auth-token'

type dependencyKeys = 'verifiedIdentity' | 'loggedIn'

export const dependencyMap: Record<dependencyKeys, () => boolean> = {
    verifiedIdentity: isIdentityVerified,
    loggedIn,
}

function isIdentityVerified(): boolean {
    const session = loadSession()
    if (session?.verified_on) {
        return true
    }
    return false
}

function loggedIn(): boolean {
    const session = loadSession()
    if (session?.identity_party_id) {
        return true
    }
    return false
}

export function redirectWrapper(
    redirectFunction: () => void,
    path: string | -1,
    dependencyKeys: dependencyKeys[]
) {
    function redirect() {
        const redirect = JSON.parse(getItem(tokenPaths.redirectAfterLogin))
        if (redirect != null) {
            return redirectFunction()
        }
        for (const dependencyKey of dependencyKeys) {
            if (!dependencyMap[dependencyKey]) {
                throw new Error('dependecy not recognised')
            }
        }
        const args = {
            path,
            dependencyKeys,
        }
        setItem(tokenPaths.redirectAfterLogin, JSON.stringify(args))
        const didRedirect = redirectIfEligible()
        if (didRedirect) {
            return null
        } else {
            return redirectFunction()
        }
    }
    return redirect
}

export function redirectIfEligible(newWindow = false): boolean {
    const redirect = JSON.parse(getItem(tokenPaths.redirectAfterLogin))
    if (redirect?.path?.length > 0) {
        for (const dependencyKey of redirect.dependencyKeys as dependencyKeys[]) {
            if (!dependencyMap[dependencyKey]()) {
                return false
            }
        }
        navigateToPage(redirect.path, newWindow)
        return true
    }
    return false
}

export function ensureRedirect(thisPathname: string): boolean {
    const redirect = JSON.parse(getItem(tokenPaths.redirectAfterLogin))
    if (redirect?.path?.length > 0) {
        const pathToCompare = redirect.path.split('?')[0]
        if (
            pathToCompare === thisPathname ||
            pathToCompare + '/' === thisPathname ||
            pathToCompare === thisPathname + '/'
        ) {
            removeItem(tokenPaths.redirectAfterLogin)
        } else {
            redirectIfEligible()
        }
        return true
    }
    return false
}

export function signOut() {
    removeStoredAuthToken()
    navigateToIndex(35)
}
