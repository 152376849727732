export const resources = {
    en: {
        translation: {
            myBikesHeading: 'My Bikes',
            myBike: 'My Bike',
            registerBike: 'Register Bike',
            searchBikesLabel: 'Search your bikes',
            searchInRegister: 'Search in register',
            registerBikeSidebar: 'Register bike',
            myBikesSidebar: 'My bikes',
            stolenBikes: 'Stolen bikes',
            ownershipTransfer: 'Ownership transfer',
            webshop: 'Webshop',
            insurance: 'Insurances',
            messages: 'Messages',
            account: 'Account',
            support: 'Support',
            insurancePolicies: 'Insurance policies',
            bikes: 'Bikes',
            bike: 'Bike',
            eBike: 'e-Bike',
            bikeDetails: 'Bike Details',
            identification: 'Identification',
            active: 'Active',
            makeSearchable: 'Make searchable',
            private: 'Private',
            reportedStolen: 'Reported stolen',
            editBike: 'Edit bike',
            frameNumber: 'Frame number',
            missing: 'MISSING',
            licensePlate: 'License plate',
            optional: 'OPTIONAL',
            mayBeRegistered: 'May be registered',
            brand: 'Brand',
            category: 'Category',
            color: 'Color',
            notProvided: 'Not provided',
            colors: {
                black: 'Black',
                gray: 'Gray',
                blue: 'Blue',
                green: 'Green',
                white: 'White',
                red: 'Red',
                special: 'Special Color',
                orange: 'Orange',
                yellow: 'Yellow',
                brown: 'Brown',
                purple: 'Purple',
                multi: 'Multi',
                pink: 'Pink',
            },
            reportBikeFound: 'Report bike found',
            seePublicDisplay: 'See public display',
            documents: 'Documents',
            equipment: 'Equipment',
            type: 'Type',
            myBikeDetailsBikeTabDescription:
                'The unique IDs and serial numbers that connect your bike to you',
            detailsAndCharacteristicsOfBike: 'Details and characteristics of your bike',
            model: 'Model',
            yes: 'Yes',
            no: 'No',
            buy: 'Buy',
            deleteBike: 'Delete bike',
            viewTheftReport: 'View theft report',
            reportBikeStolen: 'Report bike stolen',
            bikeCannotBeIdentified: 'Your bike cannot be identified!',
            toAddFrameAndLicensePlate: 'To add frame number and license plate ID',
            picture: 'Picture',
            download: 'Download',
            addDocumentToBike: 'Add a document to your bike.',
            youCanAddFrameNumber: 'You can add frame number, receipt, lock certificate or other.',
            addEquipmentToBike: 'Add equipment to your bike',
            addBatteryOrOtherAccessory: 'Add a battery or other accessory to your bike.',
            battery: 'Battery',
            seeBatteriesForEbike: 'See batteries associated with your e-bike.',
            serialNumber: 'Serial number',
            plateId: 'Plate-ID',
            bikeLocks: 'Bike locks',
            seeBikeLocks: 'See bike locks associated with your bike.',
            brandAndModel: 'Brand & Model',
            VarefaktaNumber: 'Varefakta number',
            accessories: 'Accessories',
            addVariousAccessories: 'Add various accessories to your bike.',
            logout: 'Log out',
            details: 'Details',
            importantInformation:
                'Important information about your bike that allows others to identify it through the registry.',
            takePictureFrameNumber: 'Take a picture of the frame number on your bike',
            takeOneOrMorePictures:
                'Take one or more pictures of the frame number so that the entire frame number can be read among them.',
            pictureOfLicensePlate: 'Picture of the license plate on your bike',
            takePictureLicensePlate: 'Take a picture of the license plate on your bike',
            takePictureLicensePlateCode:
                'Take a picture of the license plate of your bike so that it is possible to read the code under the QR code.',
            publicPicture: 'Public picture',
            important: 'Important',
            bikePictureOthersSee:
                'The picture of your bike that others can see when searching the registry.',
            takePictureEntireBike: 'Take a picture of your entire bike',
            makeSureBikeVisible:
                'Make sure that as much as possible of your bike can be seen in the picture - this makes it easier to identify for others.',
            pictureOfReceipt: 'Picture of the bike receipt',
            takePictureReceipt: 'Take a picture of the receipt for your bike',
            pictureOfLockProofs: 'Picture of lock proofs',
            takePictureLockProofs: 'Take a picture of one or more lock proofs',
            pictureOfFrameNumber: 'Picture of frame number',
            bikeNickName: 'Name your bike',
            save: 'Save',
            saved: 'Saved',
            nicknameDescription: 'Name your bike, for example: "My black racer"',
            reset: 'Reset',
            enterFrameNumber: 'Enter frame number',
            doubleCheckFrameNumber: 'Double-check your frame number',
            frameNumberMatchWarning:
                'The frame number entered must match the one on the bike frame and therefore it may <strong> differ </strong> from the frame number on your receipt.',
            findFrameNumber: 'Where can I find the frame number?',
            frameNumberLocation:
                "The bike's frame number can be located in several different places. Below you can see the most common places on the bike where the frame number appears.",
            placeNumberPlate: 'Where should I place the number plate?',
            numberPlatePlacement:
                'We recommend placing your number plate at the top of the seat tube facing the direction of travel, the top tube, or the head tube. The most important thing is that it is placed somewhere where it can be seen easily and not behind any parts.',
            findCode: 'Where can I find the code?',
            findNumberPlateId: 'Find your plate-ID here',
            numberPlateIdLocation:
                'Your plate-ID is the unique sequence of numbers and letters that you find under the QR code.',
            seeExample: 'See example',
            back: 'Back',
            clickToTakeOrUploadPicture: 'Click to take or upload picture',
            whereToFindPlateId: 'Where do I find my plate-ID?',
            howToAttachNumberPlates: 'How do I attach the number plates?',
            plateShapesAndPlacement:
                'Rectangular for larger tubes - round for rear fender or under the bottom bracket',
            recommendedPlatePlacement:
                'Our recommendation is to attach the larger rectangular sticker to one of the larger tubes on your bike. We recommend attaching the round one to the rear fender, or under the bottom bracket if you do not have a rear fender.',
            enterId: 'Enter ID',
            takePictureOfPlate: 'Take a picture of the number plate on your bike',
            saveAndFinish: 'Save & finish',
            continue: 'Continue',
            asOnReceipt: 'As it appears on the receipt, if you have it',
            purchaseDate: 'Purchase date',
            selectDate: 'Select date',
            selectPastDate: 'Please select a date in the past',
            purchasePriceDkk: 'Purchase price in DKK',
            privateBikePictures: 'Pictures of your bike that only you can access on Bikekey',
            privatePictures: 'Private pictures',
            receipt: 'Receipt',
            form: 'Form',
            lockCertificate: 'Lock Certificate',
            other: 'Other',
            extraDetails: 'Extra details',
            bikePreventiveFeatures:
                'Features and accessories for your bike with preventive properties that protect it against theft',
            bikeDNA: {
                whatIsBikeDNA: 'What is BikeDNA?',
                description:
                    'BikeDNA is an optional add-on to your bike registration. BikeDNA is a transparent DNA liquid that contains thousands of microdots that light up under UV light, enabling the police in 40 countries to identify your found belongings and link criminals to a possible theft.',
                readMoreOnWebShop: 'You can read more under the menu item <strong>Webshop</strong>',
                findCode: 'Where can I find my BikeDNA code?',
                codeLocation: 'Here you will find your BikeDNA code',
                codeLocationDescription: 'See your BikeDNA code on the image marked in green.',
            },
            selectType: 'Select type',
            select: 'Select',
            searchForBrand: 'Search for brand',
            bikeData: 'Bike data',
            equipmentAndAccessoriesDescription:
                'Equipment and accessories associated with your bike',
            Dame: 'Female',
            Herre: 'Male',
            Unisex: 'Unisex',
            addEquipment: 'Add {{equipment}} to your bike',
            lock: 'Lock',
            categories: {
                city: 'City',
                mountainTour: 'Mountain',
                road: 'Road',
                racer: 'Racer',
                cargo: 'Cargo',
                hybrid: 'Hybrid Bike',
                cruiser: 'Cruiser',
                folding: 'Folding',
                bmx: 'BMX',
            },
            otherType: 'Other type',
            profile: {
                languageSection: 'Language',
                languageSectionDesc: 'Select your preferred system language',
                systemLanguageLabel: 'System language',
                languageDanish: 'Danish',
                languageEnglish: 'English',
                languageNorwegian: 'Norwegian',
                regionLabel: 'Region',
                regionBikekeyDenmark: 'Bikekey Denmark',

                personalInformationTitle: 'Personal Information',
                personalInformationDesc: 'Update your personal information here',
                verificationTitle: 'Verification',
                verificationDesc: 'See the status of your profile verification here',
                resendEmailSuccess: 'Verification email was sent',
                mitid: 'MitID',
                verifyNow: 'Verify now',
                email: 'Email',
                resendMail: 'Resend mail',
                deleteUser: 'Delete user',
                serverNotResponded: 'Server did not respond correctly',
                changesSavedTitle: 'Changes saved',
                changesSavedDesc: 'Your account information was updated successfully.',
                changesPartiallySavedTitle: 'Changes partially saved',
                changesPartiallySavedDesc:
                    'We’ve sent an email to your new address—please click the link in that email to confirm your change. All other changes were saved successfully.',
                weSentYouAnEmail: 'We’ve sent you an email',
                weSentYouAnEmailDesc:
                    'We’ve sent an email to your new address—please click the link to confirm your change.',
                cancelButton: 'Cancel',
                saveButton: 'Save',
                address: 'Address',
                streetNameAndNumber: 'Street name & number',
                city: 'City',
                postalCode: 'Postal code',
                floor: 'Floor',
                side: 'Side',
                optionalDoor: 'Optional door',
                mobile: 'Mobile',
                phone: 'Phone',
                verification: 'Verification',
                seeVerificationStatus: 'See the status of your profile verification here',
                resendEmail: 'Resend email',
                pendingVerification: 'Pending verification',
                verified: 'You are verified',
                systemLanguage: 'System language',
                region: 'Region',
            },
            edit: 'Edit',
            nameNotProvided: 'Name not provided',
            supportChat: 'Support chat',
        },
    },
    da: {
        translation: {
            myBikesHeading: 'Mine cykler',
            myBike: 'Min cykel',
            registerBike: 'Registrer cykel',
            searchBikesLabel: 'Søg i cykler',
            searchInRegister: 'Søg i register',
            registerBikeSidebar: 'Registrer cykel',
            myBikesSidebar: 'Mine cykler',
            stolenBikes: 'Stjålne cykler',
            ownershipTransfer: 'Ejerskabsflyt',
            webshop: 'Webshop',
            insurance: 'Forsikringer',
            messages: 'Beskeder',
            account: 'Konto',
            support: 'Support',
            insurancePolicies: 'Forsikringspolicer',
            bikes: 'Cykler',
            bike: 'Cykel',
            eBike: 'El-cykel',
            bikeDetails: 'Cykeldetaljer',
            identification: 'Identifikation',
            active: 'Aktiv',
            makeSearchable: 'Gør søgbar',
            private: 'Privat',
            reportedStolen: 'Meldt stjålet',
            editBike: 'Rediger cykel',
            frameNumber: 'Stelnummer',
            missing: 'MANGLER',
            licensePlate: 'Nummerplade',
            optional: 'VALGFRI',
            mayBeRegistered: 'Kan være registreret',
            brand: 'Mærke',
            category: 'Kategori',
            color: 'Farve',
            notProvided: 'Ikke angivet',
            colors: {
                black: 'Sort',
                gray: 'Grå',
                blue: 'Blå',
                green: 'Grøn',
                white: 'Hvid',
                red: 'Rød',
                special: 'Speciel Farve',
                orange: 'Orange',
                yellow: 'Gul',
                brown: 'Brun',
                purple: 'Lilla',
                multi: 'Multi',
                pink: 'Pink',
            },
            reportBikeFound: 'Meld cykel fundet',
            seePublicDisplay: 'Se offentlig visning',
            documents: 'Dokumenter',
            equipment: 'Udstyr',
            type: 'Type',
            myBikeDetailsBikeTabDescription:
                'De unikke ID og serienumre der forbinder din cykel til dig',
            detailsAndCharacteristicsOfBike: 'Detaljer og kendetegn ved din cykel',
            model: 'Model',
            yes: 'Ja',
            no: 'Nej',
            buy: 'Køb',
            deleteBike: 'Slet cykel',
            viewTheftReport: 'Se tyverianmeldelse',
            reportBikeStolen: 'Meld cykel stjålet',
            bikeCannotBeIdentified: 'Din cykel kan ikke identificeres!',
            toAddFrameAndLicensePlate: 'For at tilføje stelnummer og nummerplade-ID',
            picture: 'Billede',
            download: 'Download',
            addDocumentToBike: 'Tilføj et dokument til din cykel.',
            youCanAddFrameNumber: 'Du kan tilføje stelnummer, kvittering, låsebevis eller andet.',
            addEquipmentToBike: 'Tilføj udstyr til din cykel',
            addBatteryOrOtherAccessory: 'Tilføj et batteri eller andet tilbehør til din cykel.',
            battery: 'Batteri',
            seeBatteriesForEbike: 'Se batterier tilknyttet din el-cykel.',
            serialNumber: 'Serienummer',
            plateId: 'Nummerplade-ID',
            bikeLocks: 'Cykellåse',
            seeBikeLocks: 'Se cykellåse tilknyttet din cykel.',
            brandAndModel: 'Mærke & Model',
            VarefaktaNumber: 'Varefakta nummer',
            accessories: 'Tilbehør',
            addVariousAccessories: 'Tilføj diverse tilbehør til din cykel.',
            logout: 'Log ud',
            details: 'Detaljer',
            importantInformation:
                'Vigtige oplysninger om din cykel der gør det muligt for andre at identificerer din cykel via registret.',
            takePictureFrameNumber: 'Tag et billede af stelnummeret på din cykel',
            takeOneOrMorePictures:
                'Tag et ellere flere billeder af stelnummeret så at man blandt dem kan aflæse hele stelnummeret',
            pictureOfLicensePlate: 'Billede af nummerplade på din cykel',
            takePictureLicensePlate: 'Tag et billede af nummerpladen på din cykel',
            takePictureLicensePlateCode:
                'Tag et billede af nummerpladen på din cykel så det er muligt at aflæse koden under QR-koden',
            publicPicture: 'Offentligt billede',
            important: 'Vigtig',
            bikePictureOthersSee: 'Billedet af din cykel, som andre kan se ved register-søgning',
            takePictureEntireBike: 'Tag et billede af hele din cykel',
            makeSureBikeVisible:
                'Sørg for at så meget som muligt af din cykel kan ses på billedet - det gør den lettere at identificere for andre',
            pictureOfReceipt: 'Billede af kvittering på cykel',
            takePictureReceipt: 'Tag et billede af kvitteringen på din cykel',
            pictureOfLockProofs: 'Billede af låsebeviser',
            takePictureLockProofs: 'Tag et billede af et eller flere låsebeviser',
            pictureOfFrameNumber: 'Billede af stelnummer',
            bikeNickName: 'Navngiv cykel',
            save: 'Gem',
            saved: 'Gemt',
            nicknameDescription: 'Navngiv din cykel, f.eks.: "Min sorte racer"',
            reset: 'Nulstil',
            enterFrameNumber: 'Indtast stelnummer',
            doubleCheckFrameNumber: 'Dobbeltcheck dit stelnummer',
            frameNumberMatchWarning:
                'Det indtastede stelnummer skal matche det på cyklens stel og derfor kan det <strong> afvige </strong> fra stelnummeret på din kvittering.',
            findFrameNumber: 'Hvor kan jeg finde stelnummeret?',
            frameNumberLocation:
                'Cyklens stelnummer kan befinde sig flere forskellige steder. Nedenfor her kan du se de mest hyppige steder på cyklen hvor stelnummeret fremgår.',
            placeNumberPlate: 'Hvor skal jeg sætte nummerpladen?',
            numberPlatePlacement:
                'Vi anbefaler at placere din nummerplade øverst på sadelrøret mod køreretningen, overrøret eller kronrøret. Det vigtigeste er at den er sat et sted så den kan ses nemt og ikke bag dele.',
            findCode: 'Hvor finder jeg koden?',
            findNumberPlateId: 'Find dit nummerplade-ID her',
            numberPlateIdLocation:
                'Dit nummerplade-ID er den unikke sekvens af tal og bogstaver som du finder under QR-koden.',
            seeExample: 'Se eksempel',
            back: 'Tilbage',
            clickToTakeOrUploadPicture: 'Klik for at tage eller uploade billede',
            whereToFindPlateId: 'Hvor finder jeg mit nummerplade-ID?',
            howToAttachNumberPlates: 'Hvordan påsætter jeg nummerpladerne?',
            plateShapesAndPlacement:
                'Rektangulær til større rør - rund til bagskærm eller under kranken',
            recommendedPlatePlacement:
                'Vores anbefaling er at påsætte det større rektangulære mærkat på et af de større rør på din cykel. Den runde anbefaler vi at påsætte bagskærmen, eller under kranken såfremt du ingen bagskærm har.',
            enterId: 'Indtast ID',
            takePictureOfPlate: 'Tag et billede af nummerpladen på din cykel',
            saveAndFinish: 'Gem & afslut',
            continue: 'Videre',
            asOnReceipt: 'Som det står på kvitteringen, hvis du har den',
            purchaseDate: 'Købsdato',
            selectDate: 'Vælg dato',
            selectPastDate: 'Vælg venligst en dato i fortiden',
            purchasePriceDkk: 'Købspris i DKK',
            privateBikePictures: 'Billeder af din cykel, som du kun selv kan tilgå på Bikekey',
            privatePictures: 'Private billeder',
            receipt: 'Kvittering',
            form: 'Blanket',
            lockCertificate: 'Låsebevis',
            other: 'Andet',
            extraDetails: 'Ekstra detaljer',
            bikePreventiveFeatures:
                'Kendetegn og tilbehør til din cykel med præventive egenskaber der sikrer den mod tyveri',
            bikeDNA: {
                whatIsBikeDNA: 'Hvad er BikeDNA?',
                description:
                    'BikeDNA er et ekstra tilkøb til din cykelregistrering. BikeDNA er en transparent DNA-væske som indeholder tusindvis af mikroprikker der lyser op under UV, som gør det muligt for politiet i 40 lande at identificere dine fundne ejendele og knytte kriminelle til et eventuelt tyveri.',
                readMoreOnWebShop: 'Du kan læse mere under menupunktet <strong>Webshop</strong>',
                findCode: 'Hvor finder jeg min BikeDNA kode?',
                codeLocation: 'Her finder du din BikeDNA-kode',
                codeLocationDescription: 'Se din BikeDNA-kode på billedet markeret med grønt.',
            },
            selectType: 'Vælg type',
            select: 'Vælg',
            searchForBrand: 'Søg efter mærke',
            bikeData: 'Cykeldata',
            equipmentAndAccessoriesDescription: 'Udstyr og tilbehør der er tilknyttet din cykel',
            Dame: 'Dame',
            Herre: 'Herre',
            Unisex: 'Unisex',
            addEquipment: 'Tilføj {{equipment}} til din cykel',
            lock: 'Lås',
            categories: {
                city: 'City',
                mountainTour: 'Mountain',
                road: 'Road',
                racer: 'Racer',
                cargo: 'Lad',
                hybrid: 'Hybridcykel',
                cruiser: 'Cruiser',
                folding: 'Folde',
                bmx: 'BMX',
            },
            otherType: 'Anden type',
            profile: {
                languageSection: 'Sprog',
                languageSectionDesc: 'Vælg hvilket systemsprog du ønsker',
                systemLanguageLabel: 'Systemsprog',
                languageDanish: 'Dansk',
                languageEnglish: 'Engelsk',
                languageNorwegian: 'Norsk',
                regionLabel: 'Region',
                regionBikekeyDenmark: 'Bikekey Danmark',
                personalInformationTitle: 'Personlig information',
                personalInformationDesc: 'Opdater din personlige information her',
                verificationTitle: 'Verifikation',
                verificationDesc: 'Se status på din profil verifikation her',
                resendEmailSuccess: 'E-mail sendt afsted',
                mitid: 'MitID',
                verifyNow: 'Verificer nu',
                email: 'Email',
                resendMail: 'Gensend mail',
                deleteUser: 'Slet bruger',
                serverNotResponded: 'Serveren svarede ikke korrekt',
                changesSavedTitle: 'Ændringer er gemt',
                changesSavedDesc: 'Ændringer til kontooplysninger er gemt succesfuldt.',
                changesPartiallySavedTitle: 'Ændringer er delvist gemt',
                changesPartiallySavedDesc:
                    'Vi har sendt en mail til din nye mailadresse - du bedes klikke på linket i mailen før at denne ændring træder i kraft. Alle andre ændringer er gemt succesfuldt.',
                weSentYouAnEmail: 'Vi har sendt dig en email',
                weSentYouAnEmailDesc:
                    'Vi har sendt en mail til din nye mailadresse - du bedes klikke på linket i mailen før at denne ændring træder i kraft.',
                cancelButton: 'Fortryd',
                saveButton: 'Gem',
                address: 'Adresse',
                streetNameAndNumber: 'Vejnavn & nummer',
                city: 'By',
                postalCode: 'Postnummer',
                floor: 'Etage',
                side: 'Side',
                optionalDoor: 'Evt. dør',
                mobile: 'Mobil',
                phone: 'Telefon',
                verification: 'Verifikation',
                seeVerificationStatus: 'Se status på din profilverifikation her',
                resendEmail: 'Gensend mail',
                pendingVerification: 'Mangler verificering',
                verified: 'Du er verificeret',
                systemLanguage: 'Systemsprog',
                region: 'Region',
            },
            edit: 'Rediger',
            nameNotProvided: 'Navn ikke angivet',
            supportChat: 'Support Chat',
        },
    },
    is: {
        translation: {
            myBikesHeading: 'Mín hjól',
            myBike: 'Mitt hjól',
            registerBike: 'Skrá hjól',
            searchBikesLabel: 'Leita í hjólum',
            searchInRegister: 'Leita í skrá',
            registerBikeSidebar: 'Skrá hjól',
            myBikesSidebar: 'Mín hjól',
            stolenBikes: 'Stolin hjól',
            ownershipTransfer: 'Eigendaskipti',
            webshop: 'Vefverslun',
            insurance: 'Tryggingar',
            messages: 'Skilaboð',
            account: 'Reikningur',
            support: 'Stuðningur',
            insurancePolicies: 'Tryggingarskírteini',
            bikes: 'Hjól',
            bike: 'Reiðhjól',
            eBike: 'Rafhjól',
            bikeDetails: 'Hjólaupplýsingar',
            identification: 'Auðkenning',
            active: 'Virkur',
            makeSearchable: 'Gera leitanlegt',
            private: 'Einkamál',
            reportedStolen: 'Tilkynnt stolið',
            editBike: 'Breyta hjóli',
            frameNumber: 'Grindarnúmer',
            missing: 'VANTAR',
            licensePlate: 'Skráningarnúmer',
            optional: 'VALFRJÁLST',
            mayBeRegistered: 'Gæti verið skráð',
            brand: 'Merki',
            category: 'Flokkur',
            color: 'Litur',
            notProvided: 'Ekki tilgreint',
            colors: {
                black: 'Svartur',
                gray: 'Grár',
                blue: 'Blár',
                green: 'Grænn',
                white: 'Hvítur',
                red: 'Rauður',
                special: 'Sérstakur Litur',
                orange: 'Appelsínugulur',
                yellow: 'Gulur',
                brown: 'Brúnn',
                purple: 'Fjólublár',
                multi: 'Marglitur',
                pink: 'Bleikur',
            },
            reportBikeFound: 'Tilkynna hjól fundið',
            seePublicDisplay: 'Sjá opinbera sýningu',
            documents: 'Skjöl',
            equipment: 'Búnaður',
            type: 'Tegund',
            myBikeDetailsBikeTabDescription:
                'Einstöku auðkenni og raðnúmer sem tengja hjólið þitt við þig',
            detailsAndCharacteristicsOfBike: 'Upplýsingar og einkenni hjólsins þíns',
            model: 'Fyrirmynd',
            yes: 'Já',
            no: 'Nei',
            buy: 'Kaup',
            deleteBike: 'Eyða hjóli',
            viewTheftReport: 'Sjá stuldarmál',
            reportBikeStolen: 'Tilkynna hjól stolið',
            bikeCannotBeIdentified: 'Ekki er hægt að auðkenna hjólið þitt!',
            toAddFrameAndLicensePlate: 'Til að bæta við ramma og númeraplötu',
            picture: 'Mynd',
            download: 'Sækja',
            addDocumentToBike: 'Bæta við skjali við hjólið þitt.',
            youCanAddFrameNumber: 'Þú getur bætt við grindarnúmeri, kvittun, lásvottorði eða öðru.',
            addEquipmentToBike: 'Bæta við búnaði við hjólið þitt',
            addBatteryOrOtherAccessory: 'Bæta við rafhlöðu eða öðru fylgihluti við hjólið þitt.',
            battery: 'Rafhlaða',
            seeBatteriesForEbike: 'Sjá rafhlöður sem tengjast rafmagnshjólinu þínu.',
            serialNumber: 'Raðnúmer',
            plateId: 'Skráningarnúmer',
            bikeLocks: 'Hjólalásar',
            seeBikeLocks: 'Sjá hjólalása sem tengjast hjólinu þínu.',
            brandAndModel: 'Merki og gerð',
            VarefaktaNumber: 'Varefakta númer',
            accessories: 'Fylgihlutir',
            addVariousAccessories: 'Bæta við ýmsum fylgihlutum við hjólið þitt.',
            logout: 'Útskráning',
            details: 'Upplýsingar',
            importantInformation:
                'Mikilvægar upplýsingar um hjólið þitt sem gera öðrum kleift að bera kennsl á það í gegnum skrána.',
            takePictureFrameNumber: 'Taktu mynd af ramma númerinu á hjólinu þínu',
            takeOneOrMorePictures:
                'Taktu eina eða fleiri myndir af ramma númerinu svo hægt sé að lesa allt ramma númerið á meðal þeirra.',
            pictureOfLicensePlate: 'Mynd af númeraplötu á hjólinu þínu',
            takePictureLicensePlate: 'Taktu mynd af númeraplötu á hjólinu þínu',
            takePictureLicensePlateCode:
                'Taktu mynd af númeraplötu á hjólinu þínu svo hægt sé að lesa kóðann undir QR kóðanum.',
            publicPicture: 'Opinber mynd',
            important: 'Mikilvægt',
            bikePictureOthersSee:
                'Myndin af hjólinu þínu sem aðrir geta séð þegar leitað er í skránni.',
            takePictureEntireBike: 'Taktu mynd af öllu hjólinu þínu',
            makeSureBikeVisible:
                'Gakktu úr skugga um að eins mikið og mögulegt af hjólinu þínu sést á myndinni - það gerir það auðveldara að bera kennsl á það fyrir aðra.',
            pictureOfReceipt: 'Mynd af kvittun fyrir hjólið',
            takePictureReceipt: 'Taktu mynd af kvittuninni fyrir hjólið þitt',
            pictureOfLockProofs: 'Mynd af lásvottorðum',
            takePictureLockProofs: 'Taktu mynd af einu eða fleiri lásvottorðum',
            pictureOfFrameNumber: 'Mynd af grindarnúmeri',
            extraDetails: 'Auka upplýsingar',
            // ... (other translations)
        },
    },
    no: {
        translation: {
            myBikesHeading: 'Mine sykler',
            myBike: 'Sykkelen min',
            registerBike: 'Registrer sykkel',
            searchBikesLabel: 'Søk blant syklene dine',
            searchInRegister: 'Søk i registeret',
            registerBikeSidebar: 'Registrer sykkel',
            myBikesSidebar: 'Mine sykler',
            stolenBikes: 'Stjålne sykler',
            ownershipTransfer: 'Eierskapsoverføring',
            webshop: 'Nettbutikk',
            insurance: 'Forsikringer',
            messages: 'Meldinger',
            account: 'Konto',
            support: 'Support',
            insurancePolicies: 'Forsikringspoliser',
            bikes: 'Sykler',
            bike: 'Sykkel',
            eBike: 'Elsykkel',
            bikeDetails: 'Sykkeldetaljer',
            identification: 'Identifikasjon',
            active: 'Aktiv',
            makeSearchable: 'Gjør søkbar',
            private: 'Privat',
            reportedStolen: 'Meldt stjålet',
            editBike: 'Rediger sykkel',
            frameNumber: 'Rammenummer',
            missing: 'MANGLER',
            licensePlate: 'Nummerplate',
            optional: 'VALGFRITT',
            mayBeRegistered: 'Kan være registrert',
            brand: 'Merke',
            category: 'Kategori',
            color: 'Farge',
            notProvided: 'Ikke oppgitt',
            colors: {
                black: 'Svartur',
                gray: 'Grár',
                blue: 'Blár',
                green: 'Grænn',
                white: 'Hvítur',
                red: 'Rauður',
                special: 'Sérstakur Litur',
                orange: 'Appelsínugulur',
                yellow: 'Gulur',
                brown: 'Brúnn',
                purple: 'Fjólublár',
                multi: 'Marglitur',
                pink: 'Bleikur',
            },
            reportBikeFound: 'Rapporter sykkel funnet',
            seePublicDisplay: 'Se offentlig visning',
            documents: 'Dokumenter',
            equipment: 'Utstyr',
            type: 'Type',
            myBikeDetailsBikeTabDescription:
                'De unike ID-ene og serienumrene som knytter sykkelen din til deg',
            detailsAndCharacteristicsOfBike: 'Detaljer og kjennetegn ved sykkelen din',
            model: 'Modell',
            yes: 'Ja',
            no: 'Nei',
            buy: 'Kjøp',
            deleteBike: 'Slett sykkel',
            viewTheftReport: 'Se tyverirapport',
            reportBikeStolen: 'Rapporter sykkel stjålet',
            bikeCannotBeIdentified: 'Sykkelen din kan ikke identifiseres!',
            toAddFrameAndLicensePlate: 'For å legge til rammenummer og nummerplate-ID',
            picture: 'Bilde',
            download: 'Last ned',
            addDocumentToBike: 'Legg til et dokument til sykkelen din.',
            youCanAddFrameNumber:
                'Du kan legge til rammenummer, kvittering, låsebevis eller annet.',
            addEquipmentToBike: 'Legg til utstyr til sykkelen din',
            addBatteryOrOtherAccessory:
                'Legg til et batteri eller annet tilbehør til sykkelen din.',
            battery: 'Batteri',
            seeBatteriesForEbike: 'Se batterier tilknyttet elsykkelen din.',
            serialNumber: 'Serienummer',
            plateId: 'Nummerplate-ID',
            bikeLocks: 'Sykkellåser',
            seeBikeLocks: 'Se sykkellåser tilknyttet sykkelen din.',
            brandAndModel: 'Merke & modell',
            VarefaktaNumber: 'Varefakta-nummer',
            accessories: 'Tilbehør',
            addVariousAccessories: 'Legg til forskjellige tilbehør til sykkelen din.',
            logout: 'Logg ut',
            details: 'Detaljer',
            importantInformation:
                'Viktig informasjon om sykkelen din som gjør det mulig for andre å identifisere den via registeret.',
            takePictureFrameNumber: 'Ta et bilde av rammenummeret på sykkelen din',
            takeOneOrMorePictures:
                'Ta ett eller flere bilder av rammenummeret slik at hele rammenummeret kan leses i blant dem.',
            pictureOfLicensePlate: 'Bilde av nummerplaten på sykkelen din',
            takePictureLicensePlate: 'Ta et bilde av nummerplaten på sykkelen din',
            takePictureLicensePlateCode:
                'Ta et bilde av nummerplaten på sykkelen din slik at det er mulig å lese koden under QR-koden.',
            publicPicture: 'Offentlig bilde',
            important: 'Viktig',
            bikePictureOthersSee: 'Bildet av sykkelen din som andre kan se ved søk i registeret.',
            takePictureEntireBike: 'Ta et bilde av hele sykkelen din',
            makeSureBikeVisible:
                'Pass på at så mye som mulig av sykkelen din er synlig på bildet – dette gjør den lettere å identifisere for andre.',
            pictureOfReceipt: 'Bilde av sykkelkvittering',
            takePictureReceipt: 'Ta et bilde av kvitteringen for sykkelen din',
            pictureOfLockProofs: 'Bilde av låsebevis',
            takePictureLockProofs: 'Ta et bilde av ett eller flere låsebevis',
            pictureOfFrameNumber: 'Bilde av rammenummer',
            bikeNickName: 'Gi sykkelen din et navn',
            save: 'Lagre',
            saved: 'Lagret',
            nicknameDescription: 'Gi sykkelen din et navn, for eksempel: "Min svarte racer"',
            reset: 'Nullstill',
            enterFrameNumber: 'Skriv inn rammenummer',
            doubleCheckFrameNumber: 'Dobbeltsjekk rammenummeret ditt',
            frameNumberMatchWarning:
                'Det innskrevne rammenummeret må stemme overens med det på sykkelrammen og kan derfor <strong>avvike</strong> fra rammenummeret på kvitteringen din.',
            findFrameNumber: 'Hvor finner jeg rammenummeret?',
            frameNumberLocation:
                'Sykkelens rammenummer kan være plassert flere ulike steder. Nedenfor kan du se de vanligste stedene på sykkelen der rammenummeret er.',
            placeNumberPlate: 'Hvor skal jeg sette nummerplaten?',
            numberPlatePlacement:
                'Vi anbefaler å plassere nummerplaten øverst på seterøret vendt mot kjøreretningen, overrøret eller styrerøret. Det viktigste er at den er plassert et sted der den lett kan sees og ikke er skjult av andre deler.',
            findCode: 'Hvor finner jeg koden?',
            findNumberPlateId: 'Finn nummerplate-ID-en din her',
            numberPlateIdLocation:
                'Din nummerplate-ID er den unike sekvensen av tall og bokstaver du finner under QR-koden.',
            seeExample: 'Se eksempel',
            back: 'Tilbake',
            clickToTakeOrUploadPicture: 'Klikk for å ta eller laste opp bilde',
            whereToFindPlateId: 'Hvor finner jeg nummerplate-ID-en min?',
            howToAttachNumberPlates: 'Hvordan fester jeg nummerplatene?',
            plateShapesAndPlacement:
                'Rektangulær for større rør - rund for bakskjerm eller under krankhuset',
            recommendedPlatePlacement:
                'Vi anbefaler å feste det større rektangulære klistremerket på et av de større rørene på sykkelen din. Vi anbefaler å feste den runde på bakskjermen, eller under krankhuset hvis du ikke har bakskjerm.',
            enterId: 'Skriv inn ID',
            takePictureOfPlate: 'Ta et bilde av nummerplaten på sykkelen din',
            saveAndFinish: 'Lagre & fullfør',
            continue: 'Fortsett',
            asOnReceipt: 'Som det står på kvitteringen, hvis du har den',
            purchaseDate: 'Kjøpsdato',
            selectDate: 'Velg dato',
            selectPastDate: 'Vennligst velg en dato i fortiden',
            purchasePriceDkk: 'Kjøpspris i DKK',
            privateBikePictures: 'Bilder av sykkelen din som kun du kan få tilgang til på Bikekey',
            privatePictures: 'Private bilder',
            receipt: 'Kvittering',
            form: 'Blankett',
            lockCertificate: 'Låsebevis',
            other: 'Annet',
            extraDetails: 'Ekstra detaljer',
            bikePreventiveFeatures:
                'Kjennetegn og tilbehør for sykkelen din med forebyggende egenskaper som beskytter den mot tyveri',
            bikeDNA: {
                whatIsBikeDNA: 'Hva er BikeDNA?',
                description:
                    'BikeDNA er et ekstra tillegg til din sykkelregistrering. BikeDNA er en gjennomsiktig DNA-væske som inneholder tusenvis av mikroprikker som lyser opp under UV-lys, noe som gjør det mulig for politiet i 40 land å identifisere dine funnede eiendeler og knytte kriminelle til et eventuelt tyveri.',
                readMoreOnWebShop: 'Du kan lese mer under menyelementet <strong>Webshop</strong>',
                findCode: 'Hvor finner jeg BikeDNA-koden min?',
                codeLocation: 'Her finner du BikeDNA-koden din',
                codeLocationDescription: 'Se BikeDNA-koden din på bildet markert med grønt.',
            },
            selectType: 'Velg type',
            select: 'Velg',
            searchForBrand: 'Søk etter merke',
            bikeData: 'Sykkeldata',
            equipmentAndAccessoriesDescription: 'Utstyr og tilbehør som er tilknyttet sykkelen din',
            Dame: 'Dame',
            Herre: 'Herre',
            Unisex: 'Unisex',
            addEquipment: 'Legg til {{equipment}} til sykkelen din',
            lock: 'Lås',
            categories: {
                city: 'By',
                mountainTour: 'Mountain',
                road: 'Landevei',
                racer: 'Racer',
                cargo: 'Lastesykkel',
                hybrid: 'Hybridsykkel',
                cruiser: 'Cruiser',
                folding: 'Sammenleggbar',
                bmx: 'BMX',
            },
            otherType: 'Annen type',
            profile: {
                languageSection: 'Språk',
                languageSectionDesc: 'Velg hvilket systemspråk du ønsker',
                systemLanguageLabel: 'Systemspråk',
                languageDanish: 'Dansk',
                languageEnglish: 'Engelsk',
                languageNorwegian: 'Norsk',
                regionLabel: 'Region',
                regionBikekeyDenmark: 'Bikekey Danmark',
                personalInformationTitle: 'Personlig informasjon',
                personalInformationDesc: 'Oppdater din personlige informasjon her',
                verificationTitle: 'Verifisering',
                verificationDesc: 'Se status for din profilverifisering her',
                resendEmailSuccess: 'E-post er sendt',
                mitid: 'MitID',
                verifyNow: 'Verifiser nå',
                email: 'E-post',
                resendMail: 'Send e-post på nytt',
                deleteUser: 'Slett bruker',
                serverNotResponded: 'Serveren svarte ikke',
                changesSavedTitle: 'Endringer lagret',
                changesSavedDesc: 'Endringer for kontoen din er lagret.',
                changesPartiallySavedTitle: 'Endringer delvis lagret',
                changesPartiallySavedDesc:
                    'Vi har sendt en e-post til den nye adressen din – vennligst klikk på lenken for å bekrefte. Alle andre endringer er lagret.',
                weSentYouAnEmail: 'Vi har sendt deg en e-post',
                weSentYouAnEmailDesc:
                    'Vi har sendt en e-post til den nye adressen din – vennligst klikk på lenken for å bekrefte endringen.',
                cancelButton: 'Avbryt',
                saveButton: 'Lagre',
                address: 'Adresse',
                streetNameAndNumber: 'Gatenavn og nummer',
                city: 'By',
                postalCode: 'Postnummer',
                floor: 'Etasje',
                side: 'Side',
                optionalDoor: 'Evt. dør',
                mobile: 'Mobil',
                phone: 'Telefon',
                verification: 'Verifisering',
                seeVerificationStatus: 'Se status på din profilverifisering her',
                resendEmail: 'Send e-post på nytt',
                pendingVerification: 'Mangler verifisering',
                verified: 'Du er verifisert',
                systemLanguage: 'Systemspråk',
                region: 'Region',
            },
            edit: 'Rediger',
            nameNotProvided: 'Navn ikke oppgitt',
            supportChat: 'Support Chat',
        },
    },
}
