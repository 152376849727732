import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './resources'

export function useI18Component() {
    const i18nInstance = i18next.createInstance()
    i18nInstance.use(initReactI18next).init({
        resources,
        lng: undefined,
        fallbackLng: { default: ['da', 'en'] },
        interpolation: {
            escapeValue: true,
        },
    })

    return i18nInstance
}
