import * as React from 'react'
import { navigate } from 'gatsby'
import SearchIcon from '@mui/icons-material/Search'
import InfoIcon from '@mui/icons-material/Info'
import KeyIcon from '@mui/icons-material/Key'
import PedalBikeIcon from '@mui/icons-material/PedalBike'
import LogoutIcon from '@mui/icons-material/Logout'
import { signOut } from '../../../utils/redirect'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import HomeIcon from '@mui/icons-material/Home'
import LogoIcon from '../../../assets/logo.png'
import TransferOwnershipIcon from '../../../assets/transfer_ownership_icon.svg'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
import MapIcon from '@mui/icons-material/Map'
import { Session } from '../../../apis/hooks/useAuth'
import { getStoredAuthToken, nullify } from '../../../utils/auth-token'
import { BikeCredit } from '../../../redux/services/webshop'
import { loadBikeCreditsOnce } from '../../../actions/webshop'
import CircularProgress from '@mui/material/CircularProgress'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { environments } from '../../../apis/http'
import jwt_decode from 'jwt-decode'

interface PageConfiguration {
    shouldIncludeUrlAuthentication: boolean
    shouldRequestSignout: boolean
}

const pageMap: Record<number | string, string | number> = {
    0: 'https://www.bikekey.com',
    '/': 0,
    '': 0,
    1: '/our-mission/',
    '/our-mission/': 1,
    2: '/advisory-board/',
    '/advisory-board/': 2,
    3: '/go-to-webshop/',
    '/go-to-webshop/': 3,
    4: '/find-distributor/', // removed
    '/find-distributor/': 4, // removed
    5: '/app/my-bikes/',
    '/app/my-bikes/': 5,
    6: '/hvordan/',
    '/hvordan/': 6,
    7: '/search/',
    '/search/': 7,
    8: '/app/settings/',
    '/app/settings/': 8,
    9: '/webshop/',
    '/webshop/': 9,
    10: '/app/register-bike/',
    '/app/register-bike/': 10,
    11: '/app/chats/',
    '/app/chats/': 11,
    12: '/signup/',
    '/signup/': 12,
    13: '/login/',
    '/login/': 13,
    14: '/app/organisations/create/',
    '/app/organisations/create/': 14,
    15: '/app/my-bikes/receive-bike/',
    '/app/my-bikes/receive-bike/': 15,
    16: 'https://www.bikekey.com/brugervilkar/',
    17: '/app/organisations/integrate-stripe/',
    '/app/organisations/integrate-stripe/': 17,
    18: '/app/redirects/signup-complete/',
    '/app/redirects/signup-complete/': 18,
    19: '/signup/newsletter/',
    '/signup/newsletter/': 19,
    20: '/app/',
    '/app/': 20,
    21: '/app/bikes/request-ownership/',
    '/app/bikes/request-ownership/': 21,
    22: '/app/my-bikes/report-stolen/',
    '/app/my-bikes/report-stolen/': 22,
    23: '/stolen-bike-map/',
    '/stolen-bike-map/': 23,
    24: '/app/referencing/',
    '/app/referencing': 24,
    // 25: '/app/register-bike/purchase-credit',
    // '/app/register-bike/purchase-credit': 25,
    26: '/purchase-failed/',
    '/purchase-failed/': 26,
    27: '/purchase-subscription-redirect/',
    '/purchase-subscription-redirect': 27,
    28: 'https://www.bikekey.com/app/kreditter/',
    29: '/app/bikes/verify-ownership/',
    '/app/bikes/verify-ownership/': 29,
    30: '/app/qr-id/',
    '/app/qr-id/': 30,
    31: 'https://www.bikekey.com/subscriptions/add-bike/',
    32: 'https://www.bikekey.com/subscriptions/bestil-cykelnummerplader/',
    33: 'https://www.bikekey.com/forretning/oversigt/',
    34: 'https://www.bikekey.com/produkter/',
    35: 'https://www.bikekey.com/',
    36: 'https://www.bikekey.com/brugervilkar/',
    '/terms/': 36,
    37: 'https://www.bikekey.com/product/bikekey-permamark/',
    38: '/app/register-bike/pre-registration/',
    '/app/register-bike/pre-registration/': 38,
    39: 'https://www.bikekey.com/ressourcer/',
    'https://www.bikekey.com/ressourcer/': 39,
    40: 'https://www.bikekey.com/product/selectadna-bikekey-kit/',
    41: '/insurance/',
    '/insurance/': 41,
    42: '/app/register-bike/pre-registration-insurance-warning/',
    '/app/register-bike/pre-registration-insurance-warning/': 42,
    43: '/signup/from-scan/', // TODO: Delete this page
    '/signup/from-scan/': 43, // TODO: Delete this page
    44: '/signup/from-scan/create-bikes-loop/', // TODO: Delete this page
    '/signup/from-scan/create-bikes-loop/': 44, // TODO: Delete this page
    45: '/forsikrings-indtegning/',
    '/forsikrings-indtegning/': 45,
    46: '/forsikrings-indtegning/overblik/',
    47: '/sticker-id/insurance-plate/', // TODO: Delete this page
    48: '/forsikrings-indtegning/order-confirmation/',
    49: 'https://www.support.bikekey.com/support/home',
    50: '/org-bike-search/',
    51: '/survey/commuting-days/',
    52: '/survey/addresses/',
    53: '/survey/pendler-travel/',
    54: '/forgot-password/',
    55: 'https://www.bikekey.com/product/copenhagen-trackers-cobblestone-gps',
    56: '/insurance/self/admin/reports/',
    57: '/app/settings/caution/',
    58: '/app/settings/delete-user/',
    59: '/insurance/onboarding/insurance-type/',
    60: '/insurance/onboarding/shipping-info/',
    61: '/insurance/onboarding/consent/',
    62: '/insurance/onboarding/',
    63: '/insurance/onboarding/insurance-details/',
    64: '/support/chat/',
}
export default pageMap

function getIndexConfiguration(pageIndex: number): PageConfiguration {
    const configuration: PageConfiguration = {
        shouldIncludeUrlAuthentication: false,
        shouldRequestSignout: false,
    }
    switch (pageIndex) {
        case 0:
        case 28:
        case 31:
        case 32:
        case 33:
        case 36:
        case 37:
            configuration.shouldIncludeUrlAuthentication = true
            break
        case 35:
            configuration.shouldRequestSignout = true
        default:
            break
    }
    return configuration
}

function includeAuthenticationInParams(originalParams: Record<string, string>) {
    const token = getStoredAuthToken()
    const params: Record<string, string> = {
        ...originalParams,
    }
    if (token) {
        params.bikekey_app_authentication_token = token
    }
    if (process.env.GATSBY_APP_ENV) {
        params.bikekey_app_env = process.env.GATSBY_APP_ENV
    }
    return params
}

function buildUrlParameters(pageIndex: number, originalParams: Record<string, string>) {
    let params = originalParams
    const configuration = getIndexConfiguration(pageIndex)
    if (configuration.shouldIncludeUrlAuthentication) {
        params = includeAuthenticationInParams(params)
    }
    if (configuration.shouldRequestSignout) {
        params = { ...params, signout: 'true' }
    }
    return params
}

export function navigateToPage(url: string | -1, newWindow = false) {
    if (!url) return
    if (url === -1) {
        navigate(-1)
        return
    }
    if (url.startsWith('https')) {
        // vv ----- skip mitid screen on autotest ----- vv
        const token = getStoredAuthToken()
        const sessionData = token ? nullify(jwt_decode(token)) : {}
        if (
            url.includes('mitid') &&
            process.env.GATSBY_APP_ENV === environments.development &&
            sessionData.email.startsWith('autotest+')
        ) {
            console.log('skipping mitid screen on autotest')
            return
        }
        // ^^ ----- skip mitid screen on autotest ----- ^^
        if (newWindow) {
            window.open(url, '_blank')
        } else {
            window.location.href = url
        }
    } else {
        navigate(url)
    }
}

function buildUrlFromParameters(url: string, params: Record<string, string>) {
    if (Object.keys(params).length < 1) return url
    const urlParams = new URLSearchParams()
    for (const key in params) {
        urlParams.append(key, params[key])
    }
    return url + '?' + urlParams.toString()
}

export function navigateToIndex(
    index: number | string,
    urlArguments: Record<string, string> = {},
    newWindow = false
) {
    let url
    let parameters
    if (typeof index === 'number') {
        if (index === -1) {
            navigate(-1)
            return
        }
        url = pageMap[index].toString()
        parameters = buildUrlParameters(index, urlArguments)
    } else {
        url = index
        parameters = urlArguments
    }
    return navigateToPage(buildUrlFromParameters(url, parameters), newWindow)
}

export function navigateToRegisterBike(
    hasActiveSubscription: boolean,
    firstAvailableBikeCredit: BikeCredit | undefined,
    isVip: boolean
) {
    navigateToIndex(42)
}

export const commonPages: Record<string, Record<string, number | any>> = {
    // 'Vision & Mission': {
    //     pageIndex: 1,
    //     icon: <LightbulbIcon />,
    // },
    // 'Hvem er vi?': {
    //     pageIndex: 2,
    //     icon: <InfoIcon />,
    // },
    'Stjålne cykler': {
        pageIndex: 23,
        icon: <MapIcon />,
        showOnPc: true,
    },
    Søg: {
        pageIndex: 7,
        icon: <SearchIcon />,
        showOnPc: true,
    },
}

export const appPages: Record<string, Record<string, number | any>> = {
    Hjem: {
        pageIndex: 20,
        icon: <HomeIcon />,
    },
    'Mine cykler': {
        pageIndex: 5,
        icon: <PedalBikeIcon />,
    },
    Beskeder: {
        pageIndex: 11,
        icon: <MailOutlineIcon />,
    },
    Profil: {
        pageIndex: 8,
        icon: <AccountCircleIcon />,
    },
    'Log ud': {
        pageIndex: 100,
        icon: <LogoutIcon />,
        onClick: signOut,
    },
}

const markSize = '27px'

export type DeviceType = 'pcView' | 'mobileView'
export type MenuItemRequrement = 'loggedIn' | 'loggedOut' | DeviceType

export interface SideDrawerMenuItem {
    pageIndex: number
    mobileIcon: (selected: boolean, loading?: boolean) => JSX.Element
    pcIcon: (selected: boolean, loading?: boolean) => JSX.Element
    requirements: MenuItemRequrement[]
    onClick?: () => void
}

export function shouldRenderMenuItem(
    session: Session,
    deviceType: DeviceType,
    requirements: MenuItemRequrement[]
): boolean {
    for (const requirement of requirements) {
        if (requirement === 'loggedIn' && !session?.identity_party_id) {
            return false
        }
        if (requirement === 'loggedOut' && session?.identity_party_id) {
            return false
        }
        if (requirement === 'pcView' && deviceType !== 'pcView') {
            return false
        }
        if (requirement === 'mobileView' && deviceType !== 'mobileView') {
            return false
        }
    }
    return true
}

export const sideDrawerPages: Record<string, Record<string, SideDrawerMenuItem>> = {
    bike: {
        Hjem: {
            pageIndex: 20,
            mobileIcon: (selected: boolean) => <HomeIcon />,
            pcIcon: (selected: boolean) => <HomeIcon />,
            requirements: [],
        },
        'Mine cykler': {
            pageIndex: 5,
            mobileIcon: (selected: boolean) => <PedalBikeIcon />,
            pcIcon: (selected: boolean) => <PedalBikeIcon />,
            requirements: [],
        },
        'Registrer ny cykel': {
            pageIndex: 10,
            mobileIcon: (selected: boolean, loading = false) =>
                loading ? <CircularProgress /> : <AddCircleOutlineIcon />,
            pcIcon: (selected: boolean, loading = false) =>
                loading ? <CircularProgress /> : <AddCircleOutlineIcon />,
            requirements: [],
            onClick: async () => {
                const { hasActiveSubscription, firstAvailableBikeCredit, isVip } =
                    await loadBikeCreditsOnce()
                navigateToRegisterBike(hasActiveSubscription, firstAvailableBikeCredit, isVip)
            },
        },
        'Søg cykler til Bikedesk': {
            pageIndex: 50,
            mobileIcon: (selected: boolean) => <SearchIcon />,
            pcIcon: (selected: boolean) => <SearchIcon />,
            requirements: [],
        },
        Ejerskabsflyt: {
            pageIndex: 21,
            mobileIcon: (selected: boolean) => (
                <img
                    alt="transfer ownership icon"
                    src={TransferOwnershipIcon}
                    style={{
                        width: markSize,
                        height: markSize,
                        filter: selected ? undefined : 'grayscale(100%)',
                    }}
                />
            ),
            pcIcon: (selected: boolean) => (
                <img
                    alt="transfer ownership icon"
                    src={TransferOwnershipIcon}
                    style={{
                        transform: 'scale(1.7)',
                        width: markSize,
                        height: markSize,
                    }}
                />
            ),
            requirements: [],
        },
        'Meld cykel stjålet': {
            pageIndex: 22,
            mobileIcon: (selected: boolean) => <LocalPoliceIcon />,
            pcIcon: (selected: boolean) => <LocalPoliceIcon />,
            requirements: [],
        },
        'Bestil nummerplader': {
            pageIndex: 32,
            mobileIcon: (selected: boolean) => <LocalShippingIcon />,
            pcIcon: (selected: boolean) => <LocalShippingIcon />,
            requirements: ['loggedIn'],
        },
        'Stjålne cykler': {
            pageIndex: 23,
            mobileIcon: (selected: boolean) => <MapIcon />,
            pcIcon: (selected: boolean) => <MapIcon />,
            requirements: ['loggedIn'],
        },
        Søg: {
            pageIndex: 7,
            mobileIcon: (selected: boolean) => <SearchIcon />,
            pcIcon: (selected: boolean) => <SearchIcon />,
            requirements: ['loggedIn'],
        },
    },
    profile: {
        Beskeder: {
            pageIndex: 11,
            mobileIcon: (selected: boolean) => <MailOutlineIcon />,
            pcIcon: (selected: boolean) => <MailOutlineIcon />,
            requirements: ['loggedIn'],
        },
        Profil: {
            pageIndex: 8,
            mobileIcon: (selected: boolean) => <AccountCircleIcon />,
            pcIcon: (selected: boolean) => <AccountCircleIcon />,
            requirements: ['loggedIn'],
        },
        'Log ud': {
            pageIndex: 100,
            mobileIcon: (selected: boolean) => <LogoutIcon />,
            pcIcon: (selected: boolean) => <LogoutIcon />,
            onClick: signOut,
            requirements: ['loggedIn', 'pcView'],
        },
    },
    public: {
        Forside: {
            pageIndex: 0,
            mobileIcon: (selected: boolean) => (
                <img
                    alt="homepage logo icon"
                    src={LogoIcon}
                    style={{
                        width: markSize,
                        height: markSize,
                        filter: selected ? undefined : 'grayscale(100%)',
                    }}
                />
            ),
            pcIcon: (selected: boolean) => (
                <img
                    alt="homepage logo icon"
                    src={LogoIcon}
                    style={{
                        width: markSize,
                        height: markSize,
                    }}
                />
            ),
            requirements: [],
        },
        // 'Køb nummerplade': {
        //     pageIndex: 3,
        //     mobileIcon: (selected: boolean) => <KeyIcon />,
        //     pcIcon: (selected: boolean) => <KeyIcon />,
        //     requirements: ['loggedOut'],
        // },
        Søg: {
            pageIndex: 7,
            mobileIcon: (selected: boolean) => <SearchIcon />,
            pcIcon: (selected: boolean) => <SearchIcon />,
            requirements: ['loggedOut'],
        },
        'Stjålne cykler': {
            pageIndex: 23,
            mobileIcon: (selected: boolean) => <MapIcon />,
            pcIcon: (selected: boolean) => <MapIcon />,
            requirements: ['loggedOut'],
        },
        // 'Vision & Mission': {
        //     pageIndex: 1,
        //     mobileIcon: (selected: boolean) => <LightbulbIcon />,
        //     pcIcon: (selected: boolean) => <LightbulbIcon />,
        //     requirements: [],
        // },
        'Hvem er vi?': {
            pageIndex: 2,
            mobileIcon: (selected: boolean) => <InfoIcon />,
            pcIcon: (selected: boolean) => <InfoIcon />,
            requirements: [],
        },
    },
}
